import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Modal,
  Box,
  Paper,
  TextField,
  CircularProgress,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import SpotifyWebApi from "spotify-web-api-js";
import moment from "moment-timezone";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ApiClient from "../../services/ApiClient";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Toaster from "../../components/Toaster";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/material";
import DatePicker from "react-datepicker"; // Import the date picker library
import "react-datepicker/dist/react-datepicker.css"; // Import the date picker styles
import { TablePagination } from "@mui/material";

import { styled } from "@mui/material/styles";
import CounterField from "../../components/CounterField";
import BellIconButton from "../../components/BellIconButton";
import UploadedAudioModal from "../UploadedAudioModal";

const NamazTimings = () => {
  const [loading, setLoading] = useState(true);
  const [namazTimings, setNamazTimings] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [mapIndex,setMapIndex] = useState('');                    //value will be set when model is opened
  const [selectedOption, setSelectedOption] = useState("Uploaded Audio");
  const [searchValue, setSearchValue] = useState("");
  const [spotifyAudioData, setSpotifyAudioData] = useState([]);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState({});
  const [uploadedMusicData, setUploadedMusicData] = useState([]);
  const [filteredUploadedMusic, setFilteredUploadedMusic] = useState([]);
  const [filteredSpotifyAudioData, setFilteredSpotifyAudioData] = useState([]);
  const [audioData,setAudioData] = useState({});
  const [favoriteAudios, setFavoriteAudios] = useState([]);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [validationHoursError, setValidationHoursError] = useState("");
  const [validationMinutesError, setValidationMinutesError] = useState("");
  const [validationSecondsError, setValidationSecondsError] = useState("");
  const [toasterState, setToasterState] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [count, setCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [audioSchedule, setAudioSchedule] = useState([]);
  const [sortedColumn, setSortedColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' or 'desc'
  const [currentPage, setCurrentPage] = useState(1);
  const [location, setLocation] = useState({});
  const [isLocation, setIsLocation] = useState(false);
  const [updateButton,setUpdateButton] = useState(false);
  const [audioLoad,setAudioLoad] = useState(false);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const timezone = moment.tz.guess();
        const data = { latitude, longitude, timezone };
        setLocation(data);
        setIsLocation(true);
      });
    }
  }, []);

  //set index and open modal 
  const handleSelectAudio = (index) => {
    setModalOpen(true);  //namaz audio model should open here
    setMapIndex(index); 
  };
  const handleCloseModal = () => {
    setMapIndex('');
    setModalOpen(false);
    setTimeModalOpen(false);
  };

  const handleTimeCloseModal = () => {
    setTimeModalOpen(false);
  };

  const setNamazTime = (time) => {
    const defaultTime = time.slice(0, 5);
    const [h, m] = defaultTime.split(":");
    setHours(h);
    setMinutes(m);
    setSeconds("00");
  };

  const handleToasterClose = () => {
    setToasterState({
      ...toasterState,
      open: false,
    });
  };

  const handleToasterOpen = (type, message) => {
    setToasterState({
      open: true,
      type,
      message,
    });
    setTimeout(handleToasterClose, 3000);
  };
  useEffect(() => {
    const fetchAudioList = async () => {
      try {
        const response = await ApiClient.get("api/v1/playlist/");
        if (response.status === 200) {
          const audioFiles = response.data.data.audios.map((audio,index) => ({
            name: audio.name.split("_")[0],
            audioFullName: audio.name,
            url: audio.url,
            musicId: audio.id,
            duration: audio.size,
            isFav: audio.isFav,
            isPlaylist: audio.isPlaylist,
          }));
          const updatedAudioFiles = audioFiles.map((audio) => ({
            ...audio,
            isPlaying: false,
          }));
          setUploadedMusicData(updatedAudioFiles);
          const audioSchedule = audioFiles
            .filter((audio) => audio.isPlaylist)
            .map((audio, index) => ({
              id: index + 1,
              audioFile: audio.audioFullName,
              type: "uploaded",
              time: "",
              adjustedTime: 0,
              isEnabled: true,
              namaz: "",
              audioUrl: audio.url,
              reminderType: "quran",
              duration: `${parseInt(audio.duration / 60000)}`,
              playing: false,
            }));
          setAudioSchedule(audioSchedule);
          setLoading(false);
        } else {
          handleToasterOpen("error", "Failed to fetch audio list.");
        }
      } catch (error) {
        handleToasterOpen("error", "Something went wrong.");
      }
    };

    fetchAudioList();
  }, []);

  useEffect(() => {
    const filteredMusic = uploadedMusicData.filter((music) =>
      music.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUploadedMusic(filteredMusic);
  }, [searchValue]);

  const postFavoriteAudio = async (musicId) => {
    try {
      const response = await ApiClient.post(
        `api/v1/playlist/audio/fav/${musicId}`
      );
      const data = response.data;
      if (data.code === 200) {
        handleToasterOpen("success", "Favorite audio added successfully!");
      } else {
        handleToasterOpen("error", "Favorite audio failed. Please try again.");
      }
    } catch (error) {
      handleToasterOpen(
        "error",
        "An error occurred while adding favorite audio. Please try again."
      );
    }
  };

  const handleFavoriteToggle = (musicId) => {
    const updatedMusicData = uploadedMusicData.map((music) => {
      if (music.musicId === musicId) {
        return {
          ...music,
          isFav: !music.isFav,
        };
      }
      return music;
    });

    setUploadedMusicData(updatedMusicData);

    if (favoriteAudios.includes(musicId)) {
      setFavoriteAudios(favoriteAudios.filter((id) => id !== musicId));
      postFavoriteAudio(musicId);
    } else {
      setFavoriteAudios([...favoriteAudios, musicId]);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let updatedData = [] ;
    try {
      const payload = {
        lat: location?.latitude,
        lng: location?.longitude,
        method: null,
        school: 1,
        timeZone: "2023-05-20",
      };
      const response = await ApiClient.post("api/v1/reminder/", payload);
      const data = response.data;
      updatedData = data.data.namaz;
      if (data.code === 200 && data.message === "SUCCESS") {
        setNamazTimings(data.data.namaz);
      }
      setLoading(false);
    } catch (error) {
      handleToasterOpen("error", "Error fetching namaz timings.");
      setLoading(false);
    }
    finally{
      const prayers = ['Fajr','Dhuhr','Asr','Maghrib','Isha']
      updatedData.forEach(obj=>{
        if(prayers.includes(obj.namaz))
        {
          const musicId = uploadedMusicData.find(item => {
            const audioFileName = item.audioFullName.split('.')[0];
            if (audioFileName.includes(obj.audioFile.split('.')[0])) {
              return true; // Return true when the condition is met
            }
            return false; // Return false if the condition is not met
          })?.musicId;
          setAudioData(prevData => ({
            ... prevData,
            [obj.namaz] : {
              audioName : obj.audioFile.split(".mp3")[0],
              audioType : obj.reminderType,
              audioUrl : obj.audioUrl,
              isEnabled : obj.isEnabled,
              adjustedTime:obj.adjustedTime,
              audioId :  musicId ,
              time : obj.time,
            }
          }));
    }});
    }
  };

  const showUpdate = () => {
    if(!updateButton){
      setUpdateButton(true);
    }
  }
  const hideUpdate = () => {
    if(updateButton){
      setUpdateButton(false);
    }
  }

  const handleUpdate = () => {
    try{
      Object.keys(audioData).forEach(key => {
        handleSetReminder(key,audioData[key].adjustedTime,audioData[key].isEnabled,audioData[key].time);
      })
        handleToasterOpen("success", "Changes saved successfully!"); 
        hideUpdate();
        fetchData();   //re fetch data to show changes on UI 
    }
    catch(error){
      console.log('Error Updating Data',error);
      handleToasterOpen("error", "Reminder failed. Please try again.");
    }
  } 
  const handleSetReminder = async (namaz, count, isEnabled,time) => {
    try {
      const audioFileName = audioData[namaz].audioName ? audioData[namaz].audioName + ".mp3" : null;              //setting audio name for reminder 
      const payload = {
        audioFile: audioFileName,
        type: "local",
        time: time,
        adjustedTime: count,
        isEnabled: isEnabled,
        namaz: namaz,
        audioUrl: audioData[namaz].audioUrl,
        reminderType: "namaz",
        after: null,
      };
      const response = await ApiClient.post(`api/v1/reminder/save`, payload);
      // const response = {};
      const data = response.data;
      if (data.code === 200) {
        //handleToasterOpen("success", "Reminder saved successfully!");
      } 
      else {
        handleToasterOpen("error", "Reminder failed. Please try again.");
      }
    } 
    catch (error) {
      handleToasterOpen(
        "error",
        "An error occurred while saving reminder. Please try again."
      );
    }
  };

  useEffect(() => {
    setFilteredUploadedMusic(uploadedMusicData);
  }, [uploadedMusicData]);

  useEffect(() => {
    if (audioPlayer) {
      return () => {
        audioPlayer.pause();
      };
    }
  }, [audioPlayer]);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  //Set Audio data for all these 
  const handleAudioSelect = (audioId, name, type, audioUrl,namazIndex) => {
    
    setAudioData(prevData => ({
      ...prevData,
      [namazIndex]:{
        ... prevData[namazIndex],
        audioName : name,
        audioType : type,
        audioUrl : audioUrl,
        audioId : audioId,
      }
    }));
    showUpdate();
  };

  const handleSaveMusic = () => {
    handleCloseModal();
  };

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchValue(searchValue);
  };

  const handleSearchUploadedMusic = (event) => {
    const searchValue = event.target.value;

    setSearchValue(searchValue);
  };

  const handlePlayPause = (previewUrl, trackId) => {
    if (audioPlayer && audioPlayer.src === previewUrl) {
      if (audioPlayer.paused) {
        setAudioPlayer((prevAudioPlayer) => {
          prevAudioPlayer.play();
          return prevAudioPlayer;
        });
        updateTrackIsPlaying(trackId, true);
      } else {
        setAudioPlayer((prevAudioPlayer) => {
          prevAudioPlayer.pause();
          return prevAudioPlayer;
        });
        updateTrackIsPlaying(trackId, false);
      }
    } else {
      setAudioPlayer(new Audio(previewUrl));
      updateTrackIsPlaying(trackId, true);
    }
  };
  //used by handle play pause
  const updateTrackIsPlaying = (trackId, isPlaying) => {
    setSpotifyAudioData((prevData) => {
      const newData = [...prevData];
      newData.forEach((playlist) => {
        playlist.tracks.forEach((track) => {
          if (track.id === trackId) {
            track.isPlaying = isPlaying;
          } else {
            track.isPlaying = false;
          }
        });
      });
      return newData;
    });
  };


  useEffect(() => {
    if(!audioData){
      fetchData();
    }
  },[]);
  //used for setting isplaying status of audio track by handleUplaoded Play Pause
  const updateUploadedTrackIsPlaying = (trackId, isPlaying) => {
    setUploadedMusicData((prevData) => {
      const newData = [...prevData];
      newData.forEach((playlist) => {
        if (playlist.musicId === trackId) {
          playlist.isPlaying = isPlaying;
        } else {
          playlist.isPlaying = false;
        }
      });
      return newData;
    });
  };
  let newAudioPlayer;
  const handleUploadedPlayPause = (previewUrl, trackId) => {
    try{
      if (audioPlayer && audioPlayer.src === previewUrl) {
        PlayPause(trackId);
      } else {
         newAudioPlayer = new Audio(previewUrl);
         setAudioPlayer(newAudioPlayer); // This will update the state asynchronously
         updateUploadedTrackIsPlaying(trackId, true);
         PlayPause(trackId);
      }
    }
    catch(error){
      handleToasterOpen("error","Unable to Load Audio");
    }
   };
   
  //Play/Pause audio 
  const PlayPause = (trackId) => {
    try {
       const playerToUse = newAudioPlayer || audioPlayer; // Use the newAudioPlayer if it's available, otherwise use the state
       if (playerToUse.paused) {
         playerToUse.play();
         updateUploadedTrackIsPlaying(trackId, true);
       } else {
         playerToUse.pause();
         updateUploadedTrackIsPlaying(trackId, false);
       }
    } catch (error) {
       console.log("Can't play the audio, Value of Audio Player not set up", audioPlayer);
    }
   };
   
  return (
    <>
      <Toaster {...toasterState} />
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="namaz-schedule-content"
          id="namaz-schedule-header"
        >
          <Typography variant="h6">Namaz Schedule</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h4" align="center">
            Namaz Timing
          </Typography>
          <Box p={2}>
            {loading && !isLocation ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Namaz</TableCell>
        <TableCell>Time</TableCell>
        <TableCell>Select Audio</TableCell>
        <TableCell>Adjust Schedule</TableCell>
        <TableCell>Reminder</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {namazTimings.map((namaz, index) => (
        // Ensure proper nesting here
        <>
          {(namaz.namaz === "Fajr" ||
            namaz.namaz === "Dhuhr" ||
            namaz.namaz === "Asr" ||
            namaz.namaz === "Maghrib" ||
            namaz.namaz === "Isha") && (
            <TableRow key={index}>
              {/* Check if any <td> is nested here */}
              <TableCell>{namaz.namaz}</TableCell>
              <TableCell>{namaz.time}</TableCell>
              <TableCell>
                {/* Check if any <td> is nested here */}
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={()=>handleSelectAudio(`${namaz.namaz}`)}
                >
                  Select Audio
                </Button>
                {/* Check if any <td> is nested here */}
                {audioData[namaz.namaz] && 
                  <Typography variant="body2" color="textSecondary">
                    {audioData[namaz.namaz].audioName && audioData[namaz.namaz].audioName?.length > 12 ? 
                      `${audioData[namaz.namaz].audioName.substring(0, 12)}...` : 
                      audioData[namaz.namaz].audioName
                    }
                  </Typography>
                }
              </TableCell>
              <TableCell>
                {/* Check if any <td> is nested here */}
                <CounterField
                  initialValue={namaz.adjustedTime}
                  onChangeCounterHandler={(count) => {
                    setCount(count);
                    setNamazTime(namaz.time);
                    setAudioData((prevData => ({
                      ...prevData,
                      [namaz.namaz]:{
                        ...prevData[namaz.namaz],
                        adjustedTime : count,
                      }
                    })))
                    showUpdate();
                  }}
                />
              </TableCell>
              <TableCell>
                {/* Check if any <td> is nested here */}
                <BellIconButton
                  enabled={audioData[namaz.namaz].isEnabled}
                  onClick={() => {
                    setNamazTime(namaz.time);
                    setAudioData((prevData => ({
                      ...prevData,
                      [namaz.namaz]:{
                        ...prevData[namaz.namaz],
                        isEnabled : !audioData[namaz.namaz].isEnabled,
                      }
                    })))
                    showUpdate();
                  }}
                />
              </TableCell>
            </TableRow>
          )}
        </>
      ))}
    </TableBody>
  </Table>
</TableContainer>

            )}
          </Box>
          {updateButton?
          <Grid container justifyContent="center">
          <Button
            variant="outlined"
            color="success"
            size="small"
            onClick={handleUpdate} //pass namaz name as id here this will set index and open modal  
            sx={{ flex: "1" }}
          >
            Update
          </Button>
        </Grid>
        :
        ''
        }
        </AccordionDetails>
      </Accordion>

      {/* Should open for specififc namaz */}
      <UploadedAudioModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        selectedOption={selectedOption}
        handleSelectOption={handleSelectOption}
        searchValue={searchValue}
        handleSearchChange={handleSearchChange}
        filteredSpotifyAudioData={filteredSpotifyAudioData}
        audioData={audioData}
        handleAudioSelect={handleAudioSelect}
        handlePlayPause={handlePlayPause}
        handleSearchUploadedMusic={handleSearchUploadedMusic}
        filteredUploadedMusic={filteredUploadedMusic}
        handleUploadedPlayPause={handleUploadedPlayPause}
        handleSaveMusic={handleSaveMusic}
        namazIndex={mapIndex}
      />
    </>
  );
};

export default NamazTimings;
