import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Modal,
  Box,
  Paper,
  TextField,
  CircularProgress,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import ApiClient from "../../services/ApiClient";

import Toaster from "../../components/Toaster";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import moment from "moment-timezone";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/material";
import DatePicker from "react-datepicker"; // Import the date picker library
import "react-datepicker/dist/react-datepicker.css"; // Import the date picker styles
import { TablePagination } from "@mui/material";

import BellIconButton from "../../components/BellIconButton";

function AudioSchedule() {
  const [toasterState, setToasterState] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [audioSchedule, setAudioSchedule] = useState([]);
  const [sortedColumn, setSortedColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' or 'desc'
  const [currentPage, setCurrentPage] = useState(0);
  const [uploadedMusicData, setUploadedMusicData] = useState([]);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quranData,setQuranData] = useState({});
  const [location, setLocation] = useState({});
  const [updateButton,setUpdateButton] = useState(false);
  const [updateRows,setUpdatedRows] = useState([]);
  const [namazTimings,setNamazTimings] = useState({});
  const [refresh , setRefresh] = useState(false);
  const rowsPerPage = 5;
  

  const filteredRows = audioSchedule.filter((row) =>
      row.audioFile.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedRows = filteredRows.slice().sort((a, b) => {
    const isAsc = sortDirection === "asc";
    switch (sortedColumn) {
      case "name":
        return isAsc
          ? a.namaz.localeCompare(b.namaz)
          : b.namaz.localeCompare(a.namaz);
      case "duration":
        return isAsc
          ? a.duration.localeCompare(b.duration)
          : b.duration.localeCompare(a.duration);
      // Add more cases for other columns if needed
      default:
        return 0;
    }
  });

//fetch reminders from quran 
  const fetchDataQuran = async() => {
    try {
      const payload = {
        lat: location?.latitude,
        lng: location?.longitude,
        method: null,
        school: 1,
        timeZone: "2023-05-20",
      };
      const response = await ApiClient.post("api/v1/reminder/", payload);
      const data = response.data;      
      if (data.code === 200 && data.message === "SUCCESS") {
        const temp = data.data.namaz.reduce((accumulator,obj)=>{
          if (["Fajr", "Dhuhr", "Asr", "Maghrib" , "Isha"].includes(obj.namaz)){
            accumulator[obj.namaz] = obj.time;
          }
          return accumulator;
        },{});
        setNamazTimings(temp);
        setQuranData(data.data.quran);
      }
    } catch (error) {
      handleToasterOpen("error", "Error fetching namaz timings.");
      setLoading(false);
    }
  }

  //fetch audio's from Playlist
  const fetchAudioList = async () => {
    try {
      const response = await ApiClient.get("api/v1/playlist/");
      if (response.status === 200) {
        const audioFiles = response.data.data.audios.map((audio) => ({
          name: audio.name.split("_")[0],
          audioFullName: audio.name,
          url: audio.url,
          musicId: audio.id,
          duration: (audio.size),
          isFav: audio.isFav,
          isPlaylist: audio.isPlaylist,
          isPlaying: false,
        }));

        const audioSchedule = audioFiles
          .filter((audio) => audio.isPlaylist)
          .map((audio, index) => ({
            id: index ,
            audioFile: audio.audioFullName,
            type: "uploaded",
            time: "",
            adjustedTime: 0,
            isEnabled: false,
            namaz: "",
            audioUrl: audio.url,
            reminderType: "quran",
            duration: (audio.duration/2000000).toFixed(2) ,
            playing: false,
            after:"",
            deformattedTime:"",
          }));
          
          const updatedAudioSchedule = audioSchedule.map(audio => {
            const matchedQuranKey = Object.keys(quranData).find(quranKey => quranData[quranKey].audioUrl === audio.audioUrl);
              if (matchedQuranKey) {
                  const matchedQuranObject = quranData[matchedQuranKey];
                  return {
                      ...audio,
                      isEnabled: matchedQuranObject.isEnabled,
                      time: matchedQuranObject.time,
                  };
              } else {
                  return audio;
              }
              
        });
        setAudioSchedule(updatedAudioSchedule);
        //add a variable in audioSchedule
        setUpdatedRows(updatedAudioSchedule);
        setLoading(false);
      } else {
        handleToasterOpen("error", "Failed to fetch audio list.");
      }
    } catch (error) {
      handleToasterOpen("error", "Something went wrong.");
    }
  };
  const loadData = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const timezone = moment.tz.guess();
        const data = { latitude, longitude, timezone };
        setLocation(data);
      });
    }
    fetchDataQuran();
    fetchAudioList();
  }

  //IIFE : function runs only on mount
    (() => {
      //only runs on first mount 
    if(!refresh){
      setRefresh(true);
      loadData();
    }
  })();

  const formatSchedule = (schedule) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Set to false for 24-hour format
    };
    return new Intl.DateTimeFormat("en-US", options).format(schedule);
  };


  //used for setting isplaying status of audio track by handleUplaoded Play Pause
  const updateUploadedTrackIsPlaying = (trackId, isPlaying) => {
    setUpdatedRows((prevData) => {
      const newData = [...prevData];
      newData.forEach((playlist) => {
        if (playlist.id === trackId) {
          playlist.playing = isPlaying;
        } else {
          playlist.playing = false;
        }
      });
      return newData;
    });
  };
  let newAudioPlayer;
  const handleUploadedPlayPause = (trackId, previewUrl) => {
    try {
       if (audioPlayer && audioPlayer.src === previewUrl) {
         PlayPause(trackId);
       } else {
         newAudioPlayer = new Audio(previewUrl);
         newAudioPlayer.oncanplaythrough = () => {
           setAudioPlayer(newAudioPlayer); // Update the state with the new audio player
           updateUploadedTrackIsPlaying(trackId, true);
           PlayPause(trackId);
         };
       }
    } catch (error) {
       handleToasterOpen("error", "Failed to load audio");
    }
   };

  //Play/Pause audio 
  const PlayPause = (trackId) => {
    try {
       const playerToUse = newAudioPlayer || audioPlayer; // Use the newAudioPlayer if it's available, otherwise use the state
       if (playerToUse.paused) {
         playerToUse.play();
         updateUploadedTrackIsPlaying(trackId, true);
       } else {
         playerToUse.pause();
         updateUploadedTrackIsPlaying(trackId, false);
       }
    } catch (error) {
       console.error("Can't play the audio, Value of Audio Player not set up", error);
    }
   };
  const handleToasterClose = () => {
    setToasterState({
      ...toasterState,
      open: false,
    });
  };

  const handleToasterOpen = (type, message) => {
    setToasterState({
      open: true,
      type,
      message,
    });
    setTimeout(handleToasterClose, 3000);
  };

  
  const handleSort = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortDirection("asc");
    }
  };
  
  const handleFilterChange = (event) => {
    const selectedColumn = event.target.value;
    if (selectedColumn !== sortedColumn) {
      setSortDirection("asc");
    }
    setSortedColumn(selectedColumn);
    setCurrentPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  //for selecting schedule , e.g namaz or custom
  const [selectedOption1, setSelectedOption1] = useState({});
  const handleOptionChange = (rowIndex , option) => {
    setSelectedOption1(prevValue=>({
      ...prevValue,
      [rowIndex] : option ,
    }));
  };

  
  const handleKeyChange = (rowIndex , value) => {
    //rowIndex = rowIndex - 1;
    const updatedRows = [...updateRows];
    updatedRows[rowIndex] = { // Modify the specific element
      ...updatedRows[rowIndex],
      after : value,
    };
    setUpdatedRows(updatedRows);
    setUpdateButton(true);
  };

  //toggle isEnabled option
  const isEnabledToggler = (rowIndex) => {
    if(updateRows[rowIndex].time || updateRows[rowIndex].after){
      const updatedRows = [...updateRows]; // Create a copy of the state array
    updatedRows[rowIndex] = { 
      ...updatedRows[rowIndex],
      isEnabled: !updatedRows[rowIndex].isEnabled,
    };
    setUpdatedRows(updatedRows); // Update the state with the modified array
    setUpdateButton(true);
    }
    else{
      handleToasterOpen("error", "Set time or namaz first");
    }
    
  };
  
  const handleTimeChange = ( rowIndex , timeValue) => {
    //rowIndex = rowIndex - 1;
    const updatedRows = [...updateRows];
    
    let formattedTime;
    //change time format
    if (timeValue) {
      const date = new Date(timeValue);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      
      formattedTime = `${hours
        .toString()
        .padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    }
    //update time value in updatedRows object
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      time : formattedTime,
      deformattedTime : timeValue,
    }
    
    //set state
    setUpdatedRows(updatedRows);
    setUpdateButton(true);
  };
  const handleUpdate = () => {
    try {
      //post only updated objects  
      let updatedData = [];
        updatedData = updateRows.filter((row,index) => row !== sortedRows[index]
        )
        //loop over updated objects array and set them one by one 
        for (const obj of updatedData) {
            handleData(obj);
        }
        // If all rows processed successfully, set update state to false
        setUpdateButton(false);
        handleToasterOpen('success',"Audio Schedule Updated Successfully");
        loadData();
        } 
    catch (error) {
        handleToasterOpen('error',"An error occurred during Updating Playback schedule");
        setUpdateButton(true);
    }
    };

    const handleData = async (row) => {
      try {
        const updatedObject = {
          adjustedTime: "",
          after: row?.after,
          audioFile: row?.audioFile,
          audioUrl: row?.audioUrl,
          isEnabled: row?.isEnabled,
          namaz: row?.namaz,
          reminderType: "quran",
          time: row.time ? row.time : namazTimings[row.after], // Assuming row.after is corrected to "FajrPrayer"
          type: "local",
        };
          delete updatedObject.duration;
          delete updatedObject.id;
          delete updatedObject.playing;

          const response = await ApiClient.post(`api/v1/reminder/save`, updatedObject);
          const data = response.data;
          if (data.code === 200) {
              return true; // Inicate success
          } else {
              handleToasterOpen("error", "Reminder failed. Please try again.");
              return false; // Indicate failure
          }
      } catch (error) {
          handleToasterOpen("error", "An error occurred while saving reminder. Please try again.");
          throw error; // Throw the error to be caught by the handleUpdate try...catch block
      }
  };
  
  
  const [expanded, setExpanded] = useState(false);
  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <>
      <Toaster {...toasterState} />
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="quran-schedule-content"
          id="quran-schedule-header"
        >
          {!expanded && <Typography variant="h6">Audio Schedule</Typography>}
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                  <Typography variant="h4" align="center">
                    Audio Playback Schedule
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Search Audio"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center">
              {
                sortedRows.length > 0 ?
                <>
                <Grid item xs={12} >
                  <TableContainer component={Paper} style={{ overflowX: 'auto', maxHeight: '45vh'}}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            onClick={() => handleSort("name")}
                            style={{ cursor: "pointer" }}
                          >
                            Name
                          </TableCell>
                          {/*<TableCell>Duration</TableCell>*/}
                          <TableCell>Schedule</TableCell>
                          <TableCell>Select Time</TableCell>
                          <TableCell>Play/Pause</TableCell>
                          <TableCell>Reminder</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedRows
                          .slice(
                            currentPage * rowsPerPage,
                            currentPage * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.audioFile.length > 13 ? row.audioFile.substring(0,13)+'...' : row.audioFile }</TableCell>
                             {/* <TableCell>{ Math.floor(row.duration) + ':' + Math.floor((row.duration%0.60)*100) } minutes</TableCell> */}
                              <TableCell>
                                {row.time?
                                  row.time
                                  : "Not Set"}
                              </TableCell>
                              <TableCell>
                                <select
                                  value={selectedOption1[row.id]}
                                  onChange={(e) =>
                                    handleOptionChange(row.id , e.target.value)
                                  }
                                >
                                  <option value="">Select an option</option>
                                  <option value="after">After Namaz</option>
                                  <option value="custom">Custom</option>
                                </select>
                                {selectedOption1[row.id] === "after" && (
                                  <div>
                                    <RadioGroup value={updateRows[row.id].after?updateRows[row.id].after:""} onChange={(event) => handleKeyChange(row.id , event.target.value)}>
                                      <div style={{ display: "flex" }}>
                                        <FormControlLabel
                                          value='Fajr'
                                          control={<Radio />}
                                          label="Fajar"
                                        />
                                        <FormControlLabel
                                          value = 'Dhuhr'
                                          control={<Radio />}
                                          label="Dhuhr"
                                        />
                                        <FormControlLabel
                                          value = 'Asr' 
                                          control={<Radio />}
                                          label="Asr"
                                        />
                                        <FormControlLabel
                                          value = 'Maghrib'
                                          control={<Radio />}
                                          label="Maghrib"
                                        />
                                        <FormControlLabel
                                          value = 'Isha'
                                          control={<Radio />}
                                          label="Isha"
                                        />
                                      </div>
                                    </RadioGroup>
                                  </div>
                                )}
                                {selectedOption1[row.id] === "custom" && (
                                  <div>
                                    <p>Select a time:</p>
                                    <DatePicker
                                      selected={updateRows[row.id].deformattedTime}
                                      onChange={(time) =>{
                                        handleTimeChange(row.id , time )}
                                      }
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={15}
                                      dateFormat="h:mm aa"
                                    />
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>
                                {updateRows[row.id].playing ? (
                                  <PauseCircleOutlineIcon
                                    color="primary"
                                    onClick={() =>{
                                      handleUploadedPlayPause(row.id,row.audioUrl);
                                    }
                                    }
                                    fontSize="large"
                                  />
                                ) : (
                                  <PlayCircleOutlineIcon
                                    fontSize="large"
                                    color="primary"
                                    onClick={() =>{
                                        handleUploadedPlayPause(row.id,row.audioUrl);
                                      }
                                    }
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                  <BellIconButton
                                    enabled = {updateRows[row.id].isEnabled}
                                    onClick={()=>{
                                      isEnabledToggler(row.id);
                                    }}
                                    toggleEnabled = {updateRows[row.id].time || updateRows[row.id].after ? true : false}
                                  />
                                
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {updateButton && 
                  <Grid container justifyContent="center" marginTop={2}>
                  <Button
                    variant="outlined"
                    color="success"
                    size="small"
                    onClick={handleUpdate} //pass namaz name as id here this will set index and open modal  
                    sx={{ flex: "1" }}
                  >
                    Update
                  </Button> 
                </Grid>
                }
                  {/*Pagination */}
                  <Grid item xs={12} style={{ marginTop: 15 }}>
                    <TablePagination
                      component="div"
                      count={filteredRows.length}
                      page={currentPage}
                      onPageChange={handlePageChange}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[]}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} of ${count}`
                      }
                    />
                  </Grid>
                </Grid>
                </>
                :
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">
                    No schdules found
                  </Typography>
                </Grid>
                }
            </Grid>
          </Container>
          
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default AudioSchedule;
